define("discourse/plugins/discourse-user-network-vis/discourse/routes/usernetworkvis", ["exports", "discourse/routes/discourse", "discourse/lib/ajax-error", "discourse/lib/ajax", "@ember/object"], function (_exports, _discourse, _ajaxError, _ajax, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model(data, transition) {
      return (0, _ajax.ajax)("/usernetworkvis.json").then(results => {
        return _object.default.create({
          results: results
        });
      }).catch(_ajaxError.popupAjaxError);
    }
  });
});